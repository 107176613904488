// @ts-nocheck
import { toast } from "react-toastify";
import axios from "../../../services/httpService";
import getToken from "../../../store/api/api";
import { defaultErrorMessage } from "../../../interface/comman.constant";
import { hideLoader, showLoader } from "../GlobalLoader";

async function labelDownloaderPost(urlEndpoint: any, params: any) {
  const API_URL = axios.Api;

  const UPSAPI = axios.ReportAPI || "";

  let data = {
    fileName:
      "https://5118887.app.netsuite.com/core/media/media.nl?id=7651159&c=5118887&h=ARP2em1LVtik5G4OPuVwbUE-JoKx0rcy0l1QSiI7Y2KO4plf&_xt=.pdf",
    manifestNumber: "UL-9623567",
  };
  showLoader();
  const azureB2C = localStorage.getItem("AzureB2CImpersonate");

  fetch(API_URL + UPSAPI + urlEndpoint, {
    method: "Post",
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + (await getToken()),
      ...((azureB2C && { UserImpersonated: azureB2C }) || {}),
    },
    body: JSON.stringify(data),
  })
    .then(async (response: any) => {
      //toast.error(defaultErrorMessage);
      const b = await response.blob();
      let url = URL.createObjectURL(b);
      let a = document.createElement("a");
      a.href = url;
      a.target = "_blank";
      a.click();
    })
    .catch((err) => {
      toast.error(defaultErrorMessage);
      console.log(err);
    })
    .finally(() => {
      hideLoader();
    });
}
export default labelDownloaderPost;
