import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { useState, useEffect } from "react";

interface Props {
  onChange: (pickupdate: Date) => void;
}

function DatePickerCmp({ onChange }: Props) {
  var currentDate = new Date();
  const [minDate, setMinDate] = useState(currentDate);
  const [selectedToDate, setSelectedToDate] = useState(minDate);

  var futureDate = addWeekdaysToDate(currentDate, 59);
  const [maxDate, setMaxDate] = useState(futureDate);

  function addWeekdaysToDate(startDate: any, daysToAdd: any) {
    var currentDate = new Date(startDate);
    var count = 0;
    while (count < daysToAdd) {
      currentDate.setDate(currentDate.getDate() + 1);
      // Check if it's a weekend (Saturday or Sunday)
      if (currentDate.getDay() !== 0 && currentDate.getDay() !== 6) {
        count++;
      }
    }
    return currentDate;
  }

  const isWeekday = (date: any) => {
    const day = date.getDay();
    return day !== 0 && day !== 6;
  };

  const handleToDateChange = (e: any) => {
    setSelectedToDate(e);
    onChange(e);
  };

  useEffect(() => {
    onChange(selectedToDate);
  }, []);

  return (
    <DatePicker
      showIcon
      toggleCalendarOnIconClick
      className="form-control"
      closeOnScroll={true}
      selected={selectedToDate}
      onChange={(date) => handleToDateChange(date)}
      minDate={minDate}
      maxDate={maxDate}
      filterDate={isWeekday}
      onKeyDown={(e) => {
        e.preventDefault();
      }}
    />
  );
}

export default DatePickerCmp;
