import { Button, Col, Form, Row } from "react-bootstrap";
import { usePermission } from "../../helpers/hooks/usePermissions";
import { useNavigate } from "react-router-dom";
import { useRef, useState, useEffect } from "react";
import Modal from "react-bootstrap/esm/Modal";
import DatePickerCmp from "./datePickerCmp";
import downloadUPSLabel from "../../pages/shared/upsDownload/labelDownloaderPost";
import axios from "../../../src/services/httpService";
interface Props {
  qty: number;
  disposalAccount: any;
  show: boolean;
  handleClose: () => void;
}

function UPSLabelswoInventoryPopup({
  qty,
  disposalAccount,
  show,
  handleClose,
}: Props) {
  const [upsPayload, setUpsPayload] = useState({
    disposalAccount: disposalAccount,
    pickupdate: "",
    qty: qty,
  });
  const formRef = useRef<any>();
  const permissions = usePermission(true);
  const navigate = useNavigate();

  const onYesClick = () => {
    permissions?.["StartAReturn"] &&
      navigate("/services/start-Inventoried-return");
  };

  const onNoClick = () => {
    setShowCalendar(true);
  };

  const [showCalendar, setShowCalendar] = useState(false);
  const [validated, setValidated] = useState(false);

  const handleSubmit = async (e: any) => {
    e.preventDefault();
    e.stopPropagation();
    let shipFrom = {
      shipFrom: {
        name: upsPayload.disposalAccount.disposalAccountName,
        atentionName: upsPayload.disposalAccount.disposalAccountName,
        phone: { number: upsPayload.disposalAccount.phone },
        faxNumber: "",
        address: { addressLine: upsPayload.disposalAccount.shippinG_ADDRESS },
        city: upsPayload.disposalAccount.shippinG_CITY,
        stateProvinceCode: upsPayload.disposalAccount.shippinG_STATE,
        postalCode: upsPayload.disposalAccount.shippinG_ZIP,
        countryCode: upsPayload.disposalAccount.Shipping_Country,
      },
    };
    downloadUPSLabel(`Reports/DestructionManifestsFile`, shipFrom);
    console.log(shipFrom);
  };

  const handleCallback = (childData: any) => {
    setUpsPayload((u) => ({ ...u, pickupdate: childData }));
  };

  return (
    <Modal
      show={show}
      centered={true}
      dialogClassName="modal-30w"
      className="custom_modal"
      onHide={handleClose}
    >
      <Modal.Body className="custom_modal_body">
        <div className="d-flex justify-content-center align-items-center gap-2 flex-column py-2">
          <p className="pt-2">
            This label should only be used to send non-control returns. Controls
            will need to be inventoried.
          </p>
          <p>Do you want to return controls?</p>
          <div>
            <Button variant="primary" onClick={() => onYesClick()}>
              Yes
            </Button>
            <Button variant="secondary" onClick={() => onNoClick()}>
              No
            </Button>
          </div>
        </div>

        {showCalendar && (
          <Form
            className="form_box"
            onSubmit={handleSubmit}
            noValidate
            validated={validated}
            ref={formRef}
          >
            <Row className="gx-3">
              <Col xs={12} md={6} xl={5}>
                <Form.Group
                  className="mb-3 custom-date-range"
                  controlId="exampleForm.ControlInput3"
                >
                  <Form.Label>&nbsp;</Form.Label>

                  <DatePickerCmp onChange={handleCallback}></DatePickerCmp>

                  <Form.Control.Feedback type="invalid">
                    Please Select To Date.
                  </Form.Control.Feedback>
                </Form.Group>
              </Col>
              <Col xs={12} md={6} xl={3}>
                <Form.Group className="mt-4">
                  <Button variant="primary" className="px-3" type="submit">
                    Submit
                  </Button>
                </Form.Group>
              </Col>
            </Row>
          </Form>
        )}
      </Modal.Body>
    </Modal>
  );
}

export default UPSLabelswoInventoryPopup;
